import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import "../../App.css";
const position = [30.383148984059368, 78.06207334290532];

export default function MapComponent() {
  return (
    // <div style={{ width: 450, height: 300, margin: "auto" }}>
    <MapContainer
      center={position}
      zoom={14}
      scrollWheelZoom={false}
      // className="static-map"
      // style={{width:'50%', height:'50%'}}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position}>
        <Popup>
          <a
            href="https://www.google.com/maps/dir//Lazy+Fox+Hideout/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3908d7fd37566f7d:0x66b7a341047c513f?sa=X&hl=en&ved=2ahUKEwjh-Z-3_of8AhXHslYBHaRHA1cQ9Rd6BAhIEAQ"
            target="_blank"
            rel="noreferrer"
          >
            Open in Maps
          </a>
        </Popup>
      </Marker>
    </MapContainer>
    // </div>
  );
}
