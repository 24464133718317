import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

// const backgroundImage = 'https://serving.photos.photobox.com/011928894abfc53786e85162394b939b02f3dc5a624e6f9e67a0af262af34903337099ec.jpg';
const backgroundImage = 'static/images/IMG_9891.JPG';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        // backgroundImage: {backgroundImage},
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="Background house"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Lazy Fox Hideout
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        Enjoy a peaceful hideout with serene views
      </Typography>
      <a href="https://www.airbnb.co.in/rooms/704859710767138180" target="_blank" rel="noreferrer">
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          sx={{ minWidth: 200 }}
        >
          Book now
        </Button>
      </a>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Book your stay today!
      </Typography>
    </ProductHeroLayout>
  );
}
