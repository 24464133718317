import scriptLoader from "react-async-script-loader";

const Airbnb = ({ isScriptLoaded, isScriptLoadSucceed }) => {
  if (isScriptLoaded && isScriptLoadSucceed) {
    window.AirbnbAPI.bootstrap();
  }
  return (
    <div id="bnb-section" class="airbnb-embed-frame" data-id="704859710767138180" data-view="home" data-hide-reviews="true" style={{ width: 450, height: 300, margin: "auto"}}>
        <a href="https://www.airbnb.co.in/rooms/704859710767138180?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget" rel="nofollow">
            Show On Airbnb
        </a>
        <a href="https://www.airbnb.co.in/rooms/704859710767138180?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget" rel="nofollow">
            White Stone Villa With Panoramic View
        </a>
        <script async="" src="https://www.airbnb.co.in/embeddable/airbnb_jssdk"></script>
    </div>
  );
}

export default scriptLoader(["https://www.airbnb.co.in/embeddable/airbnb_jssdk"])(
  Airbnb
);