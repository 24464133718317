import * as React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
// import ProductHowItWorks from './modules/views/ProductHowItWorks';
// import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import Airbnb from './modules/views/Airbnb';
import withRoot from './modules/withRoot';
import MapComponent from './modules/views/MapComponent'

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <Airbnb />
      <ProductValues />
      <ProductCategories />
      {/* <ProductHowItWorks /> */}
      {/* <ProductCTA />\ */}
      <ProductSmokingHero />
      <MapComponent />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
