import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Link from '@mui/material/Link';

function ProductSmokingHero() {
  return (
    <div id="contact">
      <Container
        component="section"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
      >
        <Button
          sx={{
            border: '4px solid currentColor',
            borderRadius: 0,
            height: 'auto',
            py: 2,
            px: 5,
          }}
        >
          <Typography variant="h4" component="span">
            Got any questions? Need help?
          </Typography>
        </Button>
        <Typography variant="subtitle1" sx={{ my: 3 }}>
          We are here to help. Get in touch!
        </Typography>
        <Typography variant="subtitle1" sx={{ my: 3 }}>
          Email: <Link
                variant="h5"
                underline="none"
                href="mailto:howl@lazyfoxhideout.com"
                sx={{color: 'secondary.main' }}
              >howl@lazyfoxhideout.com</Link>
        </Typography>
        <Typography variant="subtitle1" sx={{ my: 3 }}>
          Address: <Link
                variant="h5"
                underline="none"
                href="https://www.google.com/maps/dir//Lazy+Fox+Hideout/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3908d7fd37566f7d:0x66b7a341047c513f?sa=X&hl=en&ved=2ahUKEwjh-Z-3_of8AhXHslYBHaRHA1cQ9Rd6BAhIEAQ"
                sx={{color: 'secondary.main' }}
                target="_blank"
              >Village Chandrothi, Uttarakhand 248003, India</Link>
        </Typography>
        <Box
          component="img"
          src="static/icons/fox-16x16.png"
          alt="buoy"
          sx={{ width: 60 }}
        />
      </Container>
    </div>
  );
}

export default ProductSmokingHero;
